::selection {
  background: rgba(255, 126, 126, 0.6);
}

html, body {
  background: url(../assets/uwu-bg.png);
  background-repeat: repeat;
  overflow-x: hidden;
  overflow-y: hidden;
  overflow: hidden;
}

@media screen and (max-width: 600px){
  html, body {
    overflow-y: auto;
    overflow: auto;
  }
}

body {
 position: relative;
}

.App {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #222;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #222222;
}

.App-link {
  color: #61dafb;
}

.clickable {
  cursor: pointer;
}

a{
  color: #61dafb;
}

/* FLEX */
.flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.main {
  flex: 1 0 0;
  min-width: 50vw;
  text-align: center;
}

.drink {
  flex: 2 0 0;
  min-width: 50vw;
  text-align: center;
}

@media screen and (max-width: 600px){
  .flex-container {
    -webkit-flex-direction: column;
    flex-direction: column;
  }
}

/* END FLEX */

/* LEFT SIDE */
.main-header { 
  font-size: 36px;
  font-weight: 900;
  text-align: center;
}

#weOutDiv {
  display: none;
}

#tryAgain {
  display: none;
}

.we-out {
  font-size: 36px;
  font-weight: 600;
  max-height: 175px;
  min-height: 175px;
  position: relative;
}

.we-out > p {
  margin-bottom: 10px;
  margin-top: 0;
}

#locationRef {
  display: block;
  font-size: 12px;
  font-weight: 300;
  margin: 0;
}

#yelpRating {
  display: inline;
}

#reviewCount {
  font-size: 17px;
  margin-left: 20px;
  margin-top:-20px;
  display: inline;
}

#shopNameContainer {
  color: #222;
}

.input-form {
    border: 3px solid #222222;
    border-radius: 3px;
    padding: 15px;
    width: 320px;
    font-size: 14px;
}

#errorText {
  font-size: 16px;
  color: rgb(255, 126, 126);
}

.brown-btn {
  width: 261px;
  border: none;
  border-radius: 50px;
  background-color: #544C45;
  font-size: 20px;
  padding: 15px 0 15px 0;
  color: white;
  font-weight: 600;
}

.brown-btn:focus {
  outline: none;
}

.under-btn {
  font-size: 16px;
  text-decoration: underline;
  margin-top: 4px;
  margin: 0 10px;
  color: #222222;
}

#history-title {
  display: none;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.history {
  color: #999999;
  margin: 0 4px;
  font-size: 16px;
  text-decoration: underline;
}

#seeAllNearby {
  display: none;
}

/* See all results modal (background) */
.see-all {
  display: block; /* Shown on rendering  */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.see-all-content {
  background-color: #FFFFFF;
  margin: auto;
  width: 300px;
  position: absolute;
  height: 568px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#seeAllScroller {
  overflow: auto;
  position: absolute;
  margin-top: 10%;
  margin-bottom: 10%;
  margin-left: 5px;
  margin-right: 5px;
  top: 10%;
  bottom: 10%;
  left: 0;
  right: 0;
}

.see-all-content-header {
  font-size: 20px;
  text-align: center;
  margin-top: 10%;
  margin-bottom: 10px;
}

#seeAllContentResults {
  font-size: 14px;
  text-align: left;
  margin: 5px 15px;
}

.see-all-entry {
  margin: auto 10px;
}

.see-all-entry-container {
  text-align: left;
  line-height: 15px;
  margin-bottom: 7px;
  margin-top: 0;
}

.see-all-entry-name {
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  color: #222222;
}

.see-all-entry-stars {
  height: 20px;
  display: block;
}

.entry-divider {
  margin-top: 12px;
  margin-bottom: 5px;
  margin-right: 3px;
}

.close {
  float: right;
  font-size: 20px;
  font-weight: bold;
  margin: 0 5px;
}

.close-btn {
  position: absolute;
  margin: 20px 19.5px;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 600px){

  .we-out {
    min-height: 200px;
    min-width: 90%;
  }

  .input-form {
    width: 90%;
    max-width: 90%;
  }
}

/* END LEFT SIDE */

/* RIGHT SIDE */
.boba {
   height: 95vh;
}

footer {
  display: block;
  position: fixed;
  bottom: 0;
  width: 100%;
}

#loader {
  margin: auto;
  width: 40px;
  height: 40px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #e8c46f;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Chrome 29+ */
@media screen and (-webkit-min-device-pixel-ratio:0)
  and (min-resolution:.001dpcm) {
    .move-boba {
      transform: translate(0px, -18px);
      offset-path: path('M51.5333333,415.337838 L188.5,2.95578557');
      offset-distance: 0%;
      animation: drink-boba 1s ease;
    }

    @keyframes drink-boba {
      from {
        offset-distance: 0%;
      }
      to {
        offset-distance: 100%;
      }
    } 
}


@media screen and (max-width: 600px){
  .main {
    z-index: 2;
    top: 50px;
    max-width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }
  .drink {
    text-align: center;
    width: 10%;
    max-height: 50vh;
    bottom: 0;
    position: absolute;
    display: none;
  }
  .boba {
    z-index: 1;
    height: 410px;
    width: 30%;
    margin-left: 50%;
  }
  footer {
    display: block;
    position: initial;
    bottom: 0;
    width: 100%;
    margin-top: 20px;
  }
}